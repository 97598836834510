import Vue from 'vue'
import App from './App.vue'
import router from '@/router/news'
import global from '@/utlis/global.js'
import store from '@/store'
import Meta from 'vue-meta'
import '@/utlis/remcommon.js'
import VueLazyload from 'vue-lazyload'
import { initializeApp} from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { List,PullRefresh } from 'vant';
Vue.use(List);
Vue.use(PullRefresh);
// Vue.use(cell);
// import Vant from 'vant';
// import 'vant/lib/index.css';

// Vue.use(Vant);
Vue.config.productionTip = false
Vue.prototype.$global = global
const loadimage = require('@/assets/news/loading.png')
Vue.prototype.$api = global.api
Vue.use(Meta)
Vue.use(VueLazyload, {
	preLoad: 1.3,
	error: loadimage,
	loading: loadimage,
	attempt: 1,
})
import Beyla from "@shareit/beyla";
const beylaInstance = new Beyla({
  appId: "newvision_h5",
  project: "fe-analysis-data", //上报到metis项目名 创建项目时的名称 不能"_"
  logStore: "report", //metis创建日志里的日志名称 不能"_"
  env: process.env.VUE_APP_REPORT_SDK_ENV, //环境
  groupName: process.env.VUE_APP_REPORT_SDK_GROUPNAME,
  strict: false,
});
Vue.prototype.$beylaInstance = beylaInstance;

Vue.prototype.$eventrack = (msg, method, {...extensions} = {}) => {
  // map传扩展字段
  let params = {
    time: new Date(),
    message: msg,
    method: method,
    ...extensions
  };
  beylaInstance.report(params);
};
const firebaseConfig = {
  apiKey: "AIzaSyBVdS2DexLnxspRKv2sqnokzV0kGT7QDLw",
  authDomain: "mynewvision-e3c2d.firebaseapp.com",
  projectId: "mynewvision-e3c2d",
  storageBucket: "mynewvision-e3c2d.appspot.com",
  messagingSenderId: "271140691519",
  appId: "1:271140691519:web:015a8ab818e51b701d228a",
  measurementId: "G-77W90GB9R8"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
Vue.prototype.$logEvent = (event, params) => {
  logEvent(analytics, event, params);
};
Vue.prototype.$eventrackFb = (msg, method,map = {}) => {
  let params = {
    time: new Date(),
    message: msg,
     method: method,
    ...map,
  };
  logEvent(analytics, msg, params);
};
new Vue({
  router,store,
  render: h => h(App),
}).$mount('#app')
