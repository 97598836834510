<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

import '@/css/include/hhrest.scss'
import '@/css/news/index.scss'
export default {
  name: "home",
  mounted() {
    const currentURL = window.location.href;
    const url = window.location.href;
    const params = this.$global.getUrlParams(url);
    this.isDrift = params.ab === 'true' ? true : false
    let versionCode = params.version_code ? params.version_code : ''
    this.$eventrack("show_isDrift_h5", 'expose', {
      extensions: JSON.stringify({
        pve_cur: "show_isDrift",
        isDrift: params.ab
      })
    });
    this.$store.commit('UPDATE_DRIFT', this.isDrift)
    this.$store.commit('UPDATE_CODE', versionCode)
    console.log(this.$store.state.isDrift)
    if (currentURL.includes('/index.html')) {
      const newURL = currentURL.replace('/index.html', '/');
      console.log(newURL, 'newURL')
      window.location.href = newURL;
    } else {
      console.log('No need to redirect.');
    }
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8908317842209223';
    script.crossOrigin = 'anonymous';
    document.head.appendChild(script);
  },
  methods: {
    href() {

    }
  }

};
</script>

  