import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		cid: '',
		isDrift: '',
		versionCode:'',
		
	},
	mutations: {
		UPDATE_CID: (state, txt) => {
			//n是从组件中传来的参数
			state.cid = txt
		},
			UPDATE_DRIFT: (state, txt) => {
			//n是从组件中传来的参数
			state.isDrift = txt
		},
			UPDATE_CODE: (state, txt) => {
			//n是从组件中传来的参数
			state.versionCode = txt
		},
	},

})

export default store